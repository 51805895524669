/* Map Container */
.map-container {
  position: absolute;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
}


/* Hamburger Button */
.hamburger-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #353535;
  border: none;
  width:35px;
  cursor: pointer;
  padding: 15px 10px; /* Adjust the padding to control the button size */
  color: #fff;
}

.hamburger-button::before {
  font-size: 300px; /* Adjust the font size to change the icon width */
}

/* Menu */
#menu.open .menu-content {
  display: block;
}

.map-overlay {
  position: absolute;
  bottom: 235px;
  right: 0;
  background: #fff;
  margin-right: 0px;
  font-family: Arial, sans-serif;
  overflow: auto;
  border-radius: 3px;
  }
  .map-overlay2 {
    position: absolute;
    bottom:250px;
    right: 0;
    background: #fff;
    margin-right: 0px;
    font-family: Arial, sans-serif;
    overflow: auto;
    border-radius: 3px;
    }
  #legend {
    display: none;
    padding: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    line-height: 18px;
    height: 150px;
    margin-bottom: 40px;
    width: 180px;
    }
    #legend-description {
      display: none;
      text-align: center; /* Center the text horizontally */
      padding: 5px; /* Adjust the padding for spacing */
      font-weight: bold; /* Optionally, make the text bold */
    }
    .legend-key {
    display: inline-block;
    border-radius: 20%;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    }


#menu .menu-content {
  display: none;
  background : #ffffff;
  border-radius: 3px;
  width: 200px;
  max-height: 50%;
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: 'Open Sans', sans-serif;
}

#menu {
  background: #ffffff;
  position: absolute;
  z-index: 1;
  top: 62px;
  left: 10px;
  border-radius: 3px;
  width: 200px;
  max-height: 50%;
  overflow-y: scroll;
  
  font-family: 'Open Sans', sans-serif;
}

/* Menu Links */
#menu a {
  font-size: 13px;
  color:#ffffff;
  background: #b0afaf;
  display: block;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}

#menu a .link_data_botton{
  font-size: 12px;
  color: #fff;
  background-color: #878887;
  display: inline-block;
  width: 20px;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 0 5px;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 5px;
  top: 20px;
  transform: translateY(-50%);

}

#menu a:last-child {
  border: none;
}

#menu a:hover {
  background-color: #3f6e7a;
  color: #ffffff;
}

#menu a.active {
  background-color:  #605f5f;
  color: #ffffff;
}

#menu a.active:hover {
  background: #3f6e7a;
  color: #ffffff;
}

/* Group Container */
.group-container {
  display: block;
  align-items: center;
  position: relative;
}

/* Group Button */
.group-button {
  flex: 1;
  font-size: 15px;
  color: #fff;

  width :200px;
  margin: 0;
  padding: 0;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: left;
  background-color: #b0afaf; /* Pale blue background color */
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
}

.group-button:hover {
  background-color: #3f6e7a; /* Lighter blue on hover */
  color: #fff;
}

.group-button.active {
  background-color: #404040; /* Keep the pale blue color when active */
  color: #fff;
}

.group-button.active:hover {
  background: #3f6e7a; /* Lighter blue on hover when active */
}

/* Sub-layer Button */
.sub-layer-button {
  font-size: 12px;
  color: #fff;
  background-color: #878887;
  display: inline-block;
  width: 20px;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 0 5px;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 5px;
  top: 20px;
  transform: translateY(-50%);
}

/* Sub-layer Container */
.sub-layer-container {
  display: none;
  color:#ffffff;
  background: #404040;
  
  border-radius: 3px;
  width: 200px;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  top: 35px;
  left: 160px;
  z-index: 2;
}

/* Sub-layer Links */
.sub-layer-container a {
  font-size: 13px;
  color: #ffffff;
  display: block;
  margin: 0;
  padding: 0;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: right;
  background-color: #878887; /* Pale green background color */
}

.sub-layer-container a:hover {
  background-color: #888888; /* Lighter green on hover */
  color: #ffffff;
}

.sub-layer-container a.active {
  background-color: #404040; /* Keep the pale green color when active */
  color: #fbf8f8;
}

.sub-layer-container a.active:hover {
  background-color: #888888;
  color: #f5fdf4; /* Lighter green on hover when active */
}

/* Adjust the position of the sub-layer buttons slightly to the right */
.sub-layer-container a {
  padding-left: 25px; /* Add left padding to shift text to the right */
}





.toggleHighlighting {
  font-size: 13px;
  color:#ffffff;
  width: 200px;
  max-height: 200px;
  background: #b0afaf;
  display: block;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}




.toggleHighlighting:hover {
  background-color: #3f6e7a;
  color: #ffffff;
}

.toggleHighlighting.active {
  background-color:  #605f5f;
  color: #ffffff;
}

.toggleHighlighting.active:hover {
  background: #3f6e7a;
  color: #ffffff;
}

.toggleLegend {
  font-size: 13px;
  color:#ffffff;
  width: 200px;
  max-height: 200px;
  background: #b0afaf;
  display: block;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}

.toggleLegend:hover {
  background-color: #3f6e7a;
  color: #ffffff;
}

.toggleLegend.active {
  background-color:  #605f5f;
  color: #ffffff;
}

.toggleLegend.active:hover {
  background: #3f6e7a;
  color: #ffffff;
}

.map-overlay .map-overlay-inner {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
}

.map-overlay-inner fieldset {
  border: none;
  padding: 0;
  margin: 0 0 10px;
}

.map-overlay-inner fieldset:last-child {
  margin: 0;
}

.map-overlay-inner select {
  width: 100%;
}

.map-overlay-inner label {
  display: block;
  font-weight: bold;
  margin: 0 0 5px;
}

.map-overlay-inner button {
  display: inline-block;
  width: 36px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.map-overlay-inner button:focus {
  outline: none;
}

.map-overlay-inner button:hover {
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
}