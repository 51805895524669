/* DataViewer.css */

/* Style the data viewer container */
.data-viewer {
    background-color: #404040;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    padding-right:10px;
    padding-bottom: 50px;
    width: 100%;
    height: 250px;
    resize: vertical;
    position: absolute;
    bottom: 0; /* Align to the bottom */
    left: 0; /* Align to the left */
  }


  
  /* Style the title */
  .data-viewer h3 {
   left: 50%;
    font-size: 20px;
    font-weight:bold;
    color: #fdfdfd;
    margin-bottom: 8px;
  }
  
  /* Style the table */
  .data-viewer table {
    max-width: 100%;
    color: #ffffff;
    border-collapse: collapse; /* Add this line to collapse borders */
    bottom: 40px
  }
  
  /* Style table rows */
  .data-viewer tr {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd; /* Add this line to create top borders */
  }
  
  /* Style table cells */
  .data-viewer td {
    padding: 8px;
    border: 1px solid #a8a5a5; /* Add this line for black cell borders */
  }
  
  /* Style the strong element */
  .data-viewer strong {
    font-weight: bold;
  }
  
  .horizontal-scroll {
    overflow-x: auto;
  }
  
  .vertical-scroll {
    overflow-y: auto;
    max-height: 150px; /* Set a fixed height for the vertical scroll container */
  }


  
  .table-container {
    width: 100%; /* Set the width of the container to 100% to enable horizontal scrolling */
  }


  .data-viewer th {
    position: sticky;
    padding: 4px;
    border: 2px solid #b2b1b1; /* Add this line for black cell borders */
    text-align: right; /* Align text to the right */
    white-space: nowrap;
    z-index:20;
  }
  
  /* Style table cells */
  .data-viewer td {
    padding: 2px;
    border: 1px solid #c5c3c3; /* Add this line for black cell borders */
    text-align: right; /* Align text to the right */
    white-space: nowrap;
  }
  
  /* Style the strong element */
  .data-viewer strong {
    font-weight: bold;
  }
  
  .header {
    display: flex;
    
    justify-content: space-between;
    align-items: center;
  }

  /* Style the fixed table header */
table thead {
  position: sticky;
  border: 2px solid #ececec; /* Add this line for black cell borders */
    
  top: 0;
  background-color: #404040; /* Add a background color for better visibility */
  z-index: 20; /* Ensure the header stays above the table content */
}
  
  /* Style the close button */
  .close-button {
    background: none;
    border: none;
    color :white;
    font-size: 20px;
    cursor: pointer;
  }
  